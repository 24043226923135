<template>
  <div>
    <div
      class="text-detail no-close"
      v-show="TextDetail && Object.keys(TextDetail).length"
    >
      <div class="content" v-if="user && Object.keys(user).length">
        <div class="text-detail__title" v-html="TextDetail.name"></div>
      </div>
      <Breadcrumbs
        v-if="user && Object.keys(user).length"
        :pages="[
          { name: 'Библиотека', link: { name: 'Library' } },
          {
            name: 'Публикации',
            link: {
              name: 'TextMaterials',
            },
          },
          {
            name: TextDetail.name,
          },
        ]"
      />
      <div class="content">
        <div class="text-detail__row mt-4">
          <div>
            <div
              class="material__tags-wrap"
              v-if="user && Object.keys(user).length"
            >
              <div class="material__tags">
                <div class="material__tag" v-if="TextDetail.type">
                  {{ TextDetail.type.name }}
                </div>
                <div
                  class="material__tag"
                  v-for="nosology in TextDetail.nosology"
                  :key="`nos${nosology.id}`"
                  v-html="nosology.title"
                ></div>
                <div
                  class="material__tag"
                  v-for="disease in TextDetail.disease"
                  :key="`dis${disease.id}`"
                  v-html="disease.title"
                ></div>
                <div
                  class="material__tag material__tag_new"
                  v-if="TextDetail.is_new && false"
                >
                  Новинка
                </div>
              </div>
            </div>

            <div
              class="text-detail__body"
              v-if="user && Object.keys(user).length"
            >
              <img
                style="max-width: 345px"
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/pdf-pages/gipo.jpg`"
              />
              <div
                class="button button_pink mt-4 px-8 ml-xs-auto mr-xs-auto"
                style="width: fit-content"
                @click="clickOpener"
              >
                Скачать
              </div>
            </div>
            <PopupEnd
              :eventName="'lald-pdf'"
              :backUrl="{
                name: 'GffPdf',
              }"
              @close="closePopup"
              v-if="popup"
            />
          </div>
        </div>

        <div class="text-detail__buttons" v-if="false">
          <router-link
            :to="{ name: 'TextMaterials' }"
            class="text-detail__load button button_pink"
          >
            <span class="mr-2 mt-n-1">
              <svg
                width="11"
                height="18"
                viewBox="0 0 11 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 1.5L1.5 9L9 16.5"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </span>
            Назад
          </router-link>
          <div
            class="text-detail__up button button_empty-pink"
            @click="toTop()"
          >
            К началу страницы
            <span class="ml-2">
              <svg
                width="18"
                height="10"
                viewBox="0 0 18 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.25 8.75012L8.75 1.25012L16.25 8.75012"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Breadcrumbs from "../../components/pageComponents/Breadcrumbs.vue";
import Choch from "@/views/404.vue";
import Error from "@/views/Error.vue";
import "@/assets/scss/detail_content.scss";
import "@/assets/scss/clinical_case.scss";
import RightAside from "../../components/pageComponents/RightAside.vue";
import TextDetailBody from "../../components/pageComponents/TextDetailBody.vue";
import LibrarySlider from "@/components/pageComponents/LibrarySlider.vue";
import Favorite from "../../components/pageComponents/Favorite.vue";
import Presentations from "../../components/pageComponents/Presentations.vue";
import TextMaterialsSlider from "@/components/main/TextMaterialsSlider.vue";
import PopupEnd from "@/components/pageComponents/PopUpEventEnd.vue";

export default {
  metaInfo() {
    if (this.TextDetail.name) {
      return { title: this.TextDetail.name.replace(/<[^>]*>?/gm, "") };
    } else {
      return { title: this.TextDetail.name };
    }
  },
  name: "TextDetail",
  components: {
    Breadcrumbs,
    Choch,
    RightAside,
    LibrarySlider,
    Error,
    TextDetailBody,
    Favorite,
    Presentations,
    TextMaterialsSlider,
    PopupEnd,
  },
  data: () => ({
    items: [],
    popup: false,
    index: null,
  }),
  computed: {
    ...mapGetters(["user"]),
    TextDetail() {
      return {
        name: "Клинические рекомендации: современные подходы к ведению детей с гипофосфотазией",
        slug: "sovremennye-podhody-k-vedeniyu-detei-s-gipofosfataziei",
        nosology: [
          {
            id: 15,
            title: "Редкие заболевания",
            slug: "redkie-zabolevaniya",
            icon: null,
            active: false,
          },
        ],
        disease: [
          {
            id: 25,
            title: "Гипофосфатазия",
            slug: "gipofosfataziya",
            active: false,
          },
        ],
        presentations: [],
        banner_mobile: null,
        banner_tablet: null,
        banner_desktop: null,
        favorite: false,
        watch_later: false,
        type: {
          id: 4,
          name: "Статьи",
          slug: "article",
        },
        file: `https://storage.yandexcloud.net/${this.$root.backetName}/media/uploads/materials/pfd/2023/12/12/%D0%A1%D0%BE%D0%B2%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%BD%D1%8B%D0%B5_%D0%BF%D0%BE%D0%B4%D1%85%D0%BE%D0%B4%D1%8B_%D0%BA_%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D1%8E_%D0%B4%D0%B5%D1%82%D0%B5%D0%B8_%D1%81_%D0%B3%D0%B8%D0%BF%D0%BE%D1%84%D0%BE%D1%81%D1%84%D0%B0%D1%82%D0%B0%D0%B7%D0%B8%D0%B5%D0%B8_.pdf`,
        views: 31,
        announce: {
          url: `https://storage.yandexcloud.net/${this.$root.backetName}/media/uploads/materials/announce/2023/12/12/2022-12-02_10.36.16_VW0BPSq.jpg`,
          size: "7.5 Кб",
          extension: "png",
        },
        is_new: true,
        content: "&nbsp;",
        preview: "",
        active: true,
      };
    },
  },
  methods: {
    closePopup() {
      this.popup = false;
    },
    clickOpener() {
      this.$router.push({
          name: "TextDetail",
          params: { slug: this.TextDetail.slug },
        });
    },
    toTop() {
      document.body.scrollIntoView({ behavior: "smooth" });
    },
  },
  async mounted() {
    const vm = this;
    setTimeout(() => {
      if (this.user && Object.keys(this.user).length) {
        // this.$router.push({
        //   name: "TextDetail",
        //   params: { slug: this.TextDetail.slug },
        // });
      } else {
        this.popup = true;
      }
    }, 500);
  },
  watch: {
    user() {
      if (this.user && Object.keys(this.user).length) {
        // this.$router.push({
        //   name: "TextDetail",
        //   params: { slug: this.TextDetail.slug },
        // });
      } else {
        this.popup = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-detail {
  padding-top: 64px;
  @media screen and (max-width: 1220px) {
    padding-top: 40px;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 280px;
    column-gap: 136px;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      grid-template-columns: 100%;
      row-gap: 40px;
    }
  }

  &__body {
    margin-top: 64px;
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__buttons {
    margin-top: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 1220px) {
      margin-bottom: 80px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 0;
      flex-direction: column;
      align-items: stretch;
    }
  }
  &__load {
    width: 110px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  &__up {
    margin-left: auto;
    width: 213px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  }
}

.sticky {
  @media screen and (min-width: 1221px) {
    position: sticky;
    top: 115px;
  }
}

.material {
  &__tags-wrap {
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
  }
  &__tags {
    margin-right: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 1220px) {
      margin-bottom: 16px;
    }
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  &__tag {
    padding-left: 10px;
    position: relative;
    margin-right: 8px;
    // margin-bottom: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
    display: inline-flex;
    align-items: center;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3c4242;
    white-space: nowrap;
    // max-width: calc(50% - 16px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: break-spaces;

    @media screen and (max-width: 767px) {
      margin-bottom: 8px;
    }

    &::before {
      position: absolute;
      top: 10px;
      left: 0;
      transform: translateY(-50%);
      width: 2px;
      height: 2px;
      background-color: #3c4242;
      border-radius: 50%;
      display: block;
      content: "";
    }

    &:last-child {
      margin-right: 0;
    }

    &_new {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 4;
      display: inline-block;
      padding: 4px 8px;
      background: rgba(255, 255, 255, 0.8);
      border: 1px solid #d8dada;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: #1f1f1f;
    }
  }
}

.favorite {
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 16px;
  }
}
</style>

<style lang="scss">
.no-close .modal-popup__close-btn {
  display: none;
}
</style>
